import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { useTranslation } from "gatsby-plugin-react-i18next"
import emailjs from "emailjs-com"
import $ from "jquery"

library.add(fas, fab)

export default function Iletisim() {
  const { t } = useTranslation()

  useEffect(() => {
    $("footer").addClass("iletisim");
  })

  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm("service_pu1w67d", "template_k37ct8y", e.target, "user_abdiK5AKf3oHu3zb1bUU3")
      .then(
        result => {
          console.log(result.text)
          location.reload();
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <Layout>
      <SEO title="İletişim" />
      <div className="iletisim-container">
        <div className="iletisim-content">
          <div className="konum">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.2296223404664!2d29.042581315662368!3d41.020232026637494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6fff318386d%3A0x246d93429f7ac4f1!2sDusgen%20Reklam%20Ajansi!5e0!3m2!1sen!2str!4v1611684960125!5m2!1sen!2str"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="location"
            ></iframe>
          </div>
          <div className="iletisim-form">
            <form action="/tesekkurler" onSubmit={sendEmail}>
              <div className="group isim">
                <input type="text" name="user_name" required />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>{t("contact-name")}</label>
              </div>
              <div className="group telefon">
                <input type="tel" name="user_phone" className="contact-tel" required />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>{t("contact-phone")}</label>
              </div>

              <div className="group e-posta">
                <input
                  type="email"
                  name="user_email"
                  required
                  className="input-email"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>{t("contact-mail")}</label>
              </div>
              <div className="group mesaj">
                <textarea
                  rows="5"
                  name="message"
                  placeholder={t("contact-message")}
                  required
                ></textarea>
                <span className="highlight"></span>
                <span className="bar"></span>
              </div>
              <div className="gonder-btn">
                <input
                  type="submit"
                  value={t("contact-submit")}
                  className="gonder-input"
                  id="gonder-input"
                />
              </div>
            </form>
            <div className="contact-varieties">
              <div className="variety">
                <FontAwesomeIcon icon={["fas", "mobile"]} size="3x" />
                <a href="tel:+902164740036">+90 (216) 474 00 36</a>
              </div>
              <div className="variety">
                <FontAwesomeIcon icon={["fas", "map-marker-alt"]} size="3x" />
                <a href="https://goo.gl/maps/CKi2WdkNZQUVwWhH6" target="_blank" rel="noreferrer">
                  Altunizade Mah. Erdem Sk. Sabuncuoğlu Sitesi B Blok, No: 8, D: 4
                  Üsküdar/İstanbul
                </a>
              </div>
              <div className="variety">
                <FontAwesomeIcon icon={["fas", "envelope"]} size="3x" />
                <a href="mailto:info@bilalakkaya.com">info@bilalakkaya.com</a>
                <a href="mailto:info@dusgen.com">info@dusgen.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
